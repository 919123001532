'use client'

import { Dialog } from '@vinted/web-ui'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

import { OnboardingModalModel } from 'types/models'

import useOnboarding from './hooks/useOnboarding'
import OnboardingTextVariant from './OnboardingTextVariant'
import OnboardingTextActions from './OnboardingTextActions'
import SingleStep from './SingleStep'

import useBanners from '../../hooks/useBanners'

type Props = {
  banner: OnboardingModalModel
}

const OnboardingModal = ({ banner }: Props) => {
  const {
    banners: { singleStepOnboardingModal: singleStepBanner },
  } = useBanners()
  const { isSingleStepModalEnabled, isOpen, setSlide, onModalClose, onPrimaryClick } =
    useOnboarding({
      banner,
      singleStepBanner,
    })

  useTrackAbTest(useAbTest('video_onboarding_test_text_image'))
  useTrackAbTest(useAbTest('first_time_listing_guideline_v3'))
  useTrackAbTest(useAbTest(singleStepBanner?.abTest.name || ''))

  const renderContent = () => {
    if (singleStepBanner && isSingleStepModalEnabled) {
      return (
        <SingleStep
          banner={singleStepBanner}
          onClose={onModalClose}
          onPrimaryClick={onPrimaryClick}
        />
      )
    }

    return (
      <>
        <OnboardingTextVariant banner={banner} onSlideChange={setSlide} onClose={onModalClose} />
        <OnboardingTextActions
          primaryText={banner.actions.primary.title}
          onPrimaryClick={onPrimaryClick}
        />
      </>
    )
  }

  return (
    <Dialog
      show={isOpen}
      defaultCallback={onModalClose}
      closeOnOverlay
      className="u-overflow-visible u-position-relative u-zindex-bump"
    >
      {renderContent()}
    </Dialog>
  )
}

export default OnboardingModal
