'use client'

import { BottomSheet, Button, Cell, Dialog, Image, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { IvsGuidelineBannerInformationModel, IvsGuidelineBannerSectionModel } from 'types/models'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  isOpen: boolean
  onClose: () => void
  info: IvsGuidelineBannerInformationModel
}

const InfoDialog = ({ isOpen, onClose, info }: Props) => {
  const translate = useTranslate('ivs_guideline_banner')
  const breakpoint = useBreakpoint()
  const { track } = useTracking()

  const handleClose = () => {
    track(
      clickEvent({
        target: ClickableElement.IvsGuidelineBanner,
        targetDetails: 'close_ivs_how_to',
      }),
    )

    onClose()
  }

  const renderNavigation = () => {
    return (
      <Navigation
        right={
          <Button
            inline
            iconName={X24}
            styling={Button.Styling.Flat}
            onClick={handleClose}
            testId="info-dialog-close-button"
            aria={{ 'aria-label': translate('a11y.actions.close') }}
          />
        }
      />
    )
  }

  const renderTitle = () => {
    return (
      <div className="u-ui-padding-large u-ui-padding-right-x4-large">
        <Text type={Text.Type.Heading} as="p">
          {info.title}
        </Text>
      </div>
    )
  }

  const renderSection = ({ imageUrl, title, body }: IvsGuidelineBannerSectionModel) => {
    return (
      <div key={title} className="u-flexbox">
        <div>
          <Image src={imageUrl} size={Image.Size.XLarge} />
        </div>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
        <div>
          <Text type={Text.Type.Title} as="p">
            {title}
          </Text>
          <Spacer size={Spacer.Size.Regular} />
          <Text type={Text.Type.Subtitle} as="p" theme="amplified">
            {body}
          </Text>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div>
        {renderTitle()}
        <Spacer size={Spacer.Size.XLarge} />
        <div className="u-ui-padding-horizontal-large">
          <SeparatedList separator={<div className="ivs-guideline-dialog__content-separator" />}>
            {info.sections.map(renderSection)}
          </SeparatedList>
        </div>
        <Spacer size={Spacer.Size.X2Large} />
      </div>
    )
  }

  const handlePrimaryClick = () => {
    track(
      clickEvent({
        target: ClickableElement.IvsGuidelineBanner,
        targetDetails: 'upload_item_ivs_how_to',
      }),
    )
  }

  const handleSecondaryClick = () => {
    track(
      clickEvent({
        target: ClickableElement.IvsGuidelineBanner,
        targetDetails: 'learn_more_ivs_how_to',
      }),
    )
  }

  const renderActions = () => {
    return (
      <Cell styling={Cell.Styling.Default}>
        <Button styling={Button.Styling.Filled} url={ITEM_UPLOAD_URL} onClick={handlePrimaryClick}>
          {info.actions.primary.title}
        </Button>
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Flat}
          url={info.actions.secondary.url}
          onClick={handleSecondaryClick}
          urlProps={{ target: '_blank' }}
        >
          {info.actions.secondary.title}
        </Button>
      </Cell>
    )
  }

  if (!isOpen) return null

  if (breakpoint.phones) {
    return (
      <BottomSheet isVisible onClose={handleClose} initialHeight={1} closeOnOverlayClick>
        {renderNavigation()}
        {renderContent()}
        <div className="u-sticky u-bottom">{renderActions()}</div>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      show
      defaultCallback={handleClose}
      closeOnOverlay
      contentDimensions={{ maxWidth: '343px' }}
    >
      {renderNavigation()}
      <div className="ivs-guideline-dialog__content">{renderContent()}</div>
      {renderActions()}
    </Dialog>
  )
}

export default InfoDialog
