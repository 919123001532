'use client'

import { useState } from 'react'
import { Icon, PromoBanner } from '@vinted/web-ui'
import { AuthenticityDiamond32, AuthenticityDiamond32Dark } from '@vinted/multichrome-icons'
import { InView } from 'react-intersection-observer'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { IvsGuidelineBannerModel } from 'types/models'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import InfoDialog from './InfoDialog'

type Props = {
  banner: IvsGuidelineBannerModel | Record<never, never>
  onClose: () => void
}

const IvsGuidelineBanner = ({ banner, onClose }: Props) => {
  const [isClosed, setIsClosed] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const isDarkMode = useIsDarkMode()
  const translate = useTranslate('ivs_guideline_banner')

  const ivsGuidelineBannerAbTest = useAbTest('ivs_guideline_banner_v3')
  const trackExpose = useTrackAbTestCallback()

  const doesBannerExist = 'title' in banner
  const { track } = useTracking()

  const handleClose = () => {
    setIsClosed(true)

    track(
      clickEvent({
        target: ClickableElement.IvsGuidelineBanner,
        targetDetails: 'dismiss_learn_how_it_works',
      }),
    )

    onClose()
  }

  const handleClick = () => {
    setShowDialog(true)

    track(
      clickEvent({
        target: ClickableElement.IvsGuidelineBanner,
        targetDetails: 'learn_how_it_works',
      }),
    )
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const renderDialog = () => {
    if (!doesBannerExist) return null

    return (
      <InfoDialog
        isOpen={showDialog}
        info={banner.additionalInformation}
        onClose={handleCloseDialog}
      />
    )
  }

  const renderContent = () => {
    if (isClosed || !doesBannerExist) return null

    return (
      <>
        <PromoBanner
          icon={<Icon name={isDarkMode ? AuthenticityDiamond32Dark : AuthenticityDiamond32} />}
          title={banner.title}
          clickable
          closable
          closeButtonAriaLabel={translate('a11y.actions.close')}
          onClick={handleClick}
          onClose={handleClose}
          body={banner.body}
          actionText={banner.actionTitle}
          styling={PromoBanner.Styling.Tight}
          testId="ivs-guideline-banner"
        />
        {renderDialog()}
      </>
    )
  }

  const handleIvsBannerExposeEvent = (inView: boolean) => {
    if (!inView) return
    if (!ivsGuidelineBannerAbTest) return
    if (ivsGuidelineBannerAbTest?.variant.toLowerCase() === 'off') {
      trackExpose(ivsGuidelineBannerAbTest)

      return
    }

    if (!doesBannerExist) return

    trackExpose(ivsGuidelineBannerAbTest)
  }

  return <InView onChange={handleIvsBannerExposeEvent}>{renderContent()}</InView>
}

export default IvsGuidelineBanner
